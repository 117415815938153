import grpcClient from '~/grpc/grpc-client'
import {
  SavePurposeRequest,
  SavePrivateCorporationRequest,
  SaveOrderFormatRequest,
  SaveContractStyleRequest,
  SaveBudgetRequest,
  ConfirmPopupDisplayForActionARequest,
  GetInitialCorporationInformationBuyerRequest,
  SaveCorporationInformationBuyerRequest,
  LimitPopupDisplayForActionBRequest,
  SaveDesiredWorkingConditionRequest
} from '~/stub/gw/attribute_info/attribute_info_service_pb'
import { AttributeInfoService } from '~/stub/gw/attribute_info/attribute_info_service_pb_service'
import GrpcAccessorBase from '../grpc-accessor-base'
import { Stripped, toStringValue, toInt32Value } from '~/grpc/grpc-util'
import { EntryFormPurposeType } from '~/stub/domain/enumeration/entry_form_purpose_type_pb'
import { EntryFormOrganizationType } from '~/stub/domain/enumeration/entry_form_organization_type_pb'
import { NullableField } from '~/types/util'
import { EntryFormOrderFormat } from '~/stub/domain/enumeration/entry_form_order_format_pb'
import { AttributeInfoAcquisitionActionType } from '~/stub/domain/enumeration/attribute_info_acquisition_action_type_pb'

export type FormInfoType = NullableField<
  Stripped<SaveCorporationInformationBuyerRequest.AsObject>,
  'dob' | 'industryId' | 'employeeNumberId' | 'budgetType' | 'plannedTime' | 'orderFormat'
>

export type SaveDesiredWorkingConditionDetailTypes = {
  purpose: EntryFormPurposeType
  private_corporation: EntryFormOrganizationType
}

class AttributeInfoAccessor extends GrpcAccessorBase {
  // 目的登録（発注者/受注者）
  savePurpose(params: Stripped<SavePurposeRequest.AsObject>, view_id: string) {
    const request = new SavePurposeRequest()
    request.setPurpose(params.purpose)
    request.setActionType(params.actionType)
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify({
      view_id,
      purpose: params.purpose
    })
    return grpcClient({
      method: AttributeInfoService.SavePurpose,
      request,
      metadata
    })
  }

  // 個人/法人登録
  savePrivateCorporation(
    params: Stripped<SavePrivateCorporationRequest.AsObject>,
    view_id: string,
    purpose: EntryFormPurposeType
  ) {
    const request = new SavePrivateCorporationRequest()
    request.setPrivateCorporation(params.privateCorporation)
    request.setActionType(params.actionType)
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify({
      view_id,
      purpose,
      private_corporation: params.privateCorporation
    })
    return grpcClient({
      method: AttributeInfoService.SavePrivateCorporation,
      request,
      metadata
    })
  }

  // 発注形式選択（都度発注/業務委託/未定）
  saveOrderFormat(params: Stripped<SaveOrderFormatRequest.AsObject>, view_id: string) {
    const request = new SaveOrderFormatRequest()
    request.setOrderFormat(params.orderFormat)
    request.setActionType(params.actionType)
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify({
      view_id,
      purpose: EntryFormPurposeType.ENTRY_FORM_PURPOSE_TYPE_BUYER,
      private_corporation: EntryFormOrganizationType.ENTRY_FORM_ORGANIZATION_TYPE_CORPORATION
    })
    return grpcClient({
      method: AttributeInfoService.SaveOrderFormat,
      request,
      metadata
    })
  }

  // 働き方選択（単発案件/業務委託/未定）
  saveContractStyle(
    params: Stripped<SaveContractStyleRequest.AsObject>,
    view_id: string,
    private_corporation: EntryFormOrganizationType
  ) {
    const request = new SaveContractStyleRequest()
    request.setContractStyle(params.contractStyle)
    request.setActionType(params.actionType)
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify({
      view_id,
      purpose: EntryFormPurposeType.ENTRY_FORM_PURPOSE_TYPE_SELLER,
      private_corporation
    })
    return grpcClient({
      method: AttributeInfoService.SaveContractStyle,
      request,
      metadata
    })
  }

  // 予算・発注予定時期選択
  saveBudget(params: Stripped<SaveBudgetRequest.AsObject>, view_id: string) {
    const request = new SaveBudgetRequest()
    request.setBudgetType(toInt32Value(params.budgetType))
    request.setPlannedTime(toInt32Value(params.plannedTime))
    request.setActionType(params.actionType)
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify({
      view_id,
      purpose: EntryFormPurposeType.ENTRY_FORM_PURPOSE_TYPE_BUYER,
      private_corporation: EntryFormOrganizationType.ENTRY_FORM_ORGANIZATION_TYPE_CORPORATION
    })
    return grpcClient({
      method: AttributeInfoService.SaveBudget,
      request,
      metadata
    })
  }

  // 施策Aのポップアップ表示済み登録
  confirmPopupDisplayForActionA() {
    const request = new ConfirmPopupDisplayForActionARequest()
    const metadata = this.getMetadata()
    return grpcClient({
      method: AttributeInfoService.ConfirmPopupDisplayForActionA,
      request,
      metadata,
      strip: true
    })
  }

  // 発注法人登録フォーム初期値取得
  getInitialCorporationInformationBuyer() {
    const request = new GetInitialCorporationInformationBuyerRequest()
    const metadata = this.getMetadata()
    return grpcClient({
      method: AttributeInfoService.GetInitialCorporationInformationBuyer,
      request,
      metadata,
      strip: true
    })
  }

  // 発注法人情報差分登録
  saveCorporationInformationBuyer(
    params: FormInfoType,
    enterpriseInvitationKey: string | null = null
  ) {
    const request = new SaveCorporationInformationBuyerRequest()
    request.setActionType(params.actionType)
    request.setLastName(toStringValue(params.lastName))
    request.setFirstName(toStringValue(params.firstName))
    request.setLastKanaName(toStringValue(params.lastKanaName))
    request.setFirstKanaName(toStringValue(params.firstKanaName))
    request.setDob(toInt32Value(params.dob))
    request.setSex(toStringValue(params.sex))
    request.setCorporateName(toStringValue(params.corporateName))
    request.setPrefecture(toStringValue(params.prefecture))
    request.setIndustryId(toInt32Value(params.industryId))
    request.setEmployeeNumberId(toInt32Value(params.employeeNumberId))
    request.setPhoneNumber(toStringValue(params.phoneNumber))
    request.setOrderFormat(
      params.orderFormat || EntryFormOrderFormat.ENTRY_FORM_ORDER_FORMAT_UNSPECIFIED
    )
    request.setBudgetType(toInt32Value(params.budgetType))
    request.setPlannedTime(toInt32Value(params.plannedTime))
    if (enterpriseInvitationKey) {
      request.setEnterpriseInvitationKey(toStringValue(enterpriseInvitationKey))
    }
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify({
      purpose: EntryFormPurposeType.ENTRY_FORM_PURPOSE_TYPE_BUYER,
      private_corporation: EntryFormOrganizationType.ENTRY_FORM_ORGANIZATION_TYPE_CORPORATION
    })
    return grpcClient({
      method: AttributeInfoService.SaveCorporationInformationBuyer,
      request,
      metadata
    })
  }

  /**
   * 差分情報取得で稼働条件を保存します。
   *
   * @param params 保存する勤務条件のパラメータ
   * @returns gRPCクライアントのレスポンス
   */
  saveDesiredWorkingCondition(
    params: SaveDesiredWorkingConditionRequest.AsObject,
    detail: SaveDesiredWorkingConditionDetailTypes
  ) {
    const method = AttributeInfoService.SaveDesiredWorkingCondition
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify(detail)
    const request = new SaveDesiredWorkingConditionRequest()

    // 差分情報取得の4属性情報取得施策で使用するため、Aを設定
    request.setActionType(
      AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_A
    )
    request.setId(params.id)
    request.setMinHourlyPay(params.minHourlyPay)
    request.setMinMonthlyPay(params.minMonthlyPay)
    request.setWeeklyWorkingDays(params.weeklyWorkingDays)
    request.setDailyWorkingHour(params.dailyWorkingHour)
    request.setWorkingTimeframesList(params.workingTimeframesList)
    request.setWorkingLocationsList(params.workingLocationsList)
    return grpcClient({
      method,
      request,
      metadata
    })
  }

  // 施策Bのフローティングバナー再表示まで日数を空けるため、離脱時にAPIコールする
  limitPopupDisplayForActionB() {
    const request = new LimitPopupDisplayForActionBRequest()
    const metadata = this.getMetadata()
    return grpcClient({
      method: AttributeInfoService.LimitPopupDisplayForActionB,
      request,
      metadata,
      strip: true
    })
  }
}

export default AttributeInfoAccessor
