// source: gw/attribute_info/attribute_info_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var domain_enumeration_entry_form_purpose_type_pb = require('../../domain/enumeration/entry_form_purpose_type_pb.js');
goog.object.extend(proto, domain_enumeration_entry_form_purpose_type_pb);
var domain_enumeration_entry_form_organization_type_pb = require('../../domain/enumeration/entry_form_organization_type_pb.js');
goog.object.extend(proto, domain_enumeration_entry_form_organization_type_pb);
var domain_enumeration_entry_form_order_format_pb = require('../../domain/enumeration/entry_form_order_format_pb.js');
goog.object.extend(proto, domain_enumeration_entry_form_order_format_pb);
var domain_enumeration_entry_form_contract_style_pb = require('../../domain/enumeration/entry_form_contract_style_pb.js');
goog.object.extend(proto, domain_enumeration_entry_form_contract_style_pb);
var domain_enumeration_attribute_info_acquisition_action_type_pb = require('../../domain/enumeration/attribute_info_acquisition_action_type_pb.js');
goog.object.extend(proto, domain_enumeration_attribute_info_acquisition_action_type_pb);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest.displayName = 'proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse.displayName = 'proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest.displayName = 'proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse.displayName = 'proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest.displayName = 'proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse.displayName = 'proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest.displayName = 'proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse.displayName = 'proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.displayName = 'proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse.displayName = 'proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest.displayName = 'proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.displayName = 'proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.displayName = 'proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse.displayName = 'proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest.displayName = 'proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse.displayName = 'proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest.displayName = 'proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse.displayName = 'proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.displayName = 'proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse.displayName = 'proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    purpose: jspb.Message.getFieldWithDefault(msg, 1, 0),
    actionType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest}
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest;
  return proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest}
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} */ (reader.readEnum());
      msg.setPurpose(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurpose();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormPurposeType purpose = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType}
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest.prototype.getPurpose = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType action_type = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType}
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest.prototype.getActionType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeRequest.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse}
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse;
  return proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse}
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SavePurposeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    privateCorporation: jspb.Message.getFieldWithDefault(msg, 1, 0),
    actionType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest}
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest;
  return proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest}
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} */ (reader.readEnum());
      msg.setPrivateCorporation(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrivateCorporation();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormOrganizationType private_corporation = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType}
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest.prototype.getPrivateCorporation = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest.prototype.setPrivateCorporation = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType action_type = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType}
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest.prototype.getActionType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationRequest.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse}
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse;
  return proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse}
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SavePrivateCorporationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderFormat: jspb.Message.getFieldWithDefault(msg, 1, 0),
    actionType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest}
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest;
  return proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest}
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat} */ (reader.readEnum());
      msg.setOrderFormat(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormOrderFormat order_format = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat}
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest.prototype.getOrderFormat = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest.prototype.setOrderFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType action_type = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType}
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest.prototype.getActionType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatRequest.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse}
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse;
  return proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse}
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveOrderFormatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractStyle: jspb.Message.getFieldWithDefault(msg, 1, 0),
    actionType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest}
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest;
  return proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest}
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormContractStyle} */ (reader.readEnum());
      msg.setContractStyle(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractStyle();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormContractStyle contract_style = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormContractStyle}
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest.prototype.getContractStyle = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormContractStyle} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormContractStyle} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest.prototype.setContractStyle = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType action_type = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType}
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest.prototype.getActionType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleRequest.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse}
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse;
  return proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse}
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveContractStyleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    budgetType: (f = msg.getBudgetType()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    plannedTime: (f = msg.getPlannedTime()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    actionType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest}
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest;
  return proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest}
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setBudgetType(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPlannedTime(value);
      break;
    case 3:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBudgetType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPlannedTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value budget_type = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.prototype.getBudgetType = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.prototype.setBudgetType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.prototype.clearBudgetType = function() {
  return this.setBudgetType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.prototype.hasBudgetType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value planned_time = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.prototype.getPlannedTime = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.prototype.setPlannedTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.prototype.clearPlannedTime = function() {
  return this.setPlannedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.prototype.hasPlannedTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType action_type = 3;
 * @return {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType}
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.prototype.getActionType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetRequest.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse}
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse;
  return proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse}
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveBudgetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest;
  return proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastName: (f = msg.getLastName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firstName: (f = msg.getFirstName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastKanaName: (f = msg.getLastKanaName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firstKanaName: (f = msg.getFirstKanaName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    dob: (f = msg.getDob()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    corporateName: (f = msg.getCorporateName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    prefecture: (f = msg.getPrefecture()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    sex: (f = msg.getSex()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse;
  return proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastName(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastKanaName(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstKanaName(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setDob(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCorporateName(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrefecture(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirstName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastKanaName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirstKanaName();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDob();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getCorporateName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrefecture();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSex();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue last_name = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.getLastName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
*/
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.setLastName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.clearLastName = function() {
  return this.setLastName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.hasLastName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue first_name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.getFirstName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
*/
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.setFirstName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.clearFirstName = function() {
  return this.setFirstName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.hasFirstName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue last_kana_name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.getLastKanaName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
*/
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.setLastKanaName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.clearLastKanaName = function() {
  return this.setLastKanaName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.hasLastKanaName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue first_kana_name = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.getFirstKanaName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
*/
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.setFirstKanaName = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.clearFirstKanaName = function() {
  return this.setFirstKanaName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.hasFirstKanaName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int64Value dob = 5;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.getDob = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
*/
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.setDob = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.clearDob = function() {
  return this.setDob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.hasDob = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue corporate_name = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.getCorporateName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
*/
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.setCorporateName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.clearCorporateName = function() {
  return this.setCorporateName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.hasCorporateName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue prefecture = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.getPrefecture = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
*/
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.setPrefecture = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.clearPrefecture = function() {
  return this.setPrefecture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.hasPrefecture = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue sex = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.getSex = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
*/
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.setSex = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse} returns this
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.clearSex = function() {
  return this.setSex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.GetInitialCorporationInformationBuyerResponse.prototype.hasSex = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lastName: (f = msg.getLastName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firstName: (f = msg.getFirstName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastKanaName: (f = msg.getLastKanaName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firstKanaName: (f = msg.getFirstKanaName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    dob: (f = msg.getDob()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    sex: (f = msg.getSex()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    corporateName: (f = msg.getCorporateName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    prefecture: (f = msg.getPrefecture()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    industryId: (f = msg.getIndustryId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    employeeNumberId: (f = msg.getEmployeeNumberId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    phoneNumber: (f = msg.getPhoneNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    enterpriseInvitationKey: (f = msg.getEnterpriseInvitationKey()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    orderFormat: jspb.Message.getFieldWithDefault(msg, 14, 0),
    budgetType: (f = msg.getBudgetType()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    plannedTime: (f = msg.getPlannedTime()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest;
  return proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstName(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastKanaName(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstKanaName(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setDob(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSex(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCorporateName(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrefecture(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setIndustryId(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setEmployeeNumberId(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEnterpriseInvitationKey(value);
      break;
    case 14:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat} */ (reader.readEnum());
      msg.setOrderFormat(value);
      break;
    case 15:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setBudgetType(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPlannedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirstName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastKanaName();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirstKanaName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDob();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getSex();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCorporateName();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrefecture();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIndustryId();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getEmployeeNumberId();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEnterpriseInvitationKey();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrderFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getBudgetType();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPlannedTime();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType action_type = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getActionType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue last_name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getLastName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setLastName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.clearLastName = function() {
  return this.setLastName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.hasLastName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue first_name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getFirstName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.clearFirstName = function() {
  return this.setFirstName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.hasFirstName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue last_kana_name = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getLastKanaName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setLastKanaName = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.clearLastKanaName = function() {
  return this.setLastKanaName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.hasLastKanaName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue first_kana_name = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getFirstKanaName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setFirstKanaName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.clearFirstKanaName = function() {
  return this.setFirstKanaName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.hasFirstKanaName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int64Value dob = 6;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getDob = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setDob = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.clearDob = function() {
  return this.setDob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.hasDob = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue sex = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getSex = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setSex = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.clearSex = function() {
  return this.setSex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.hasSex = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue corporate_name = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getCorporateName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setCorporateName = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.clearCorporateName = function() {
  return this.setCorporateName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.hasCorporateName = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue prefecture = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getPrefecture = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setPrefecture = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.clearPrefecture = function() {
  return this.setPrefecture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.hasPrefecture = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Int32Value industry_id = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getIndustryId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setIndustryId = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.clearIndustryId = function() {
  return this.setIndustryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.hasIndustryId = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Int32Value employee_number_id = 11;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getEmployeeNumberId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 11));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setEmployeeNumberId = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.clearEmployeeNumberId = function() {
  return this.setEmployeeNumberId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.hasEmployeeNumberId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue phone_number = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getPhoneNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue enterprise_invitation_key = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getEnterpriseInvitationKey = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setEnterpriseInvitationKey = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.clearEnterpriseInvitationKey = function() {
  return this.setEnterpriseInvitationKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.hasEnterpriseInvitationKey = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormOrderFormat order_format = 14;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getOrderFormat = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setOrderFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional google.protobuf.Int32Value budget_type = 15;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getBudgetType = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 15));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setBudgetType = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.clearBudgetType = function() {
  return this.setBudgetType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.hasBudgetType = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Int32Value planned_time = 16;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.getPlannedTime = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 16));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.setPlannedTime = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.clearPlannedTime = function() {
  return this.setPlannedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerRequest.prototype.hasPlannedTime = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse;
  return proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveCorporationInformationBuyerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest}
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest;
  return proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest}
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionARequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shouldShow: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse}
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse;
  return proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse}
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShouldShow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShouldShow();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool should_show = 1;
 * @return {boolean}
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse.prototype.getShouldShow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse} returns this
 */
proto.coconala.protobuf.gw.attribute_info.ConfirmPopupDisplayForActionAResponse.prototype.setShouldShow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest}
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest;
  return proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest}
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nextDisplayAt: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse}
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse;
  return proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse}
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextDisplayAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNextDisplayAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 next_display_at = 1;
 * @return {number}
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse.prototype.getNextDisplayAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse} returns this
 */
proto.coconala.protobuf.gw.attribute_info.LimitPopupDisplayForActionBResponse.prototype.setNextDisplayAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    minHourlyPay: jspb.Message.getFieldWithDefault(msg, 3, 0),
    minMonthlyPay: jspb.Message.getFieldWithDefault(msg, 4, 0),
    weeklyWorkingDays: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dailyWorkingHour: jspb.Message.getFieldWithDefault(msg, 6, 0),
    workingTimeframesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    workingLocationsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest;
  return proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinHourlyPay(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinMonthlyPay(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWeeklyWorkingDays(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailyWorkingHour(value);
      break;
    case 7:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setWorkingTimeframesList(value);
      break;
    case 8:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setWorkingLocationsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMinHourlyPay();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMinMonthlyPay();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getWeeklyWorkingDays();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getDailyWorkingHour();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getWorkingTimeframesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      7,
      f
    );
  }
  f = message.getWorkingLocationsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType action_type = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.getActionType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 min_hourly_pay = 3;
 * @return {number}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.getMinHourlyPay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.setMinHourlyPay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 min_monthly_pay = 4;
 * @return {number}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.getMinMonthlyPay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.setMinMonthlyPay = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 weekly_working_days = 5;
 * @return {number}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.getWeeklyWorkingDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.setWeeklyWorkingDays = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 daily_working_hour = 6;
 * @return {number}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.getDailyWorkingHour = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.setDailyWorkingHour = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated int32 working_timeframes = 7;
 * @return {!Array<number>}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.getWorkingTimeframesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.setWorkingTimeframesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.addWorkingTimeframes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.clearWorkingTimeframesList = function() {
  return this.setWorkingTimeframesList([]);
};


/**
 * repeated int32 working_locations = 8;
 * @return {!Array<number>}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.getWorkingLocationsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.setWorkingLocationsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.addWorkingLocations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionRequest.prototype.clearWorkingLocationsList = function() {
  return this.setWorkingLocationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse;
  return proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.attribute_info.SaveDesiredWorkingConditionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.coconala.protobuf.gw.attribute_info);
