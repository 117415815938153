// package: coconala.protobuf.gw.attribute_info
// file: gw/attribute_info/attribute_info_service.proto

import * as gw_attribute_info_attribute_info_service_pb from "../../gw/attribute_info/attribute_info_service_pb";
import * as google_protobuf_wrappers_pb from "google-protobuf/google/protobuf/wrappers_pb";
import * as domain_enumeration_entry_form_purpose_type_pb from "../../domain/enumeration/entry_form_purpose_type_pb";
import * as domain_enumeration_entry_form_organization_type_pb from "../../domain/enumeration/entry_form_organization_type_pb";
import * as domain_enumeration_entry_form_order_format_pb from "../../domain/enumeration/entry_form_order_format_pb";
import * as domain_enumeration_entry_form_contract_style_pb from "../../domain/enumeration/entry_form_contract_style_pb";
import * as domain_enumeration_attribute_info_acquisition_action_type_pb from "../../domain/enumeration/attribute_info_acquisition_action_type_pb";
export class AttributeInfoService {
  static serviceName = "coconala.protobuf.gw.attribute_info.AttributeInfoService";
}
export namespace AttributeInfoService {
  export class SavePurpose {
    static readonly methodName = "SavePurpose";
    static readonly service = AttributeInfoService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_attribute_info_attribute_info_service_pb.SavePurposeRequest;
    static readonly responseType = gw_attribute_info_attribute_info_service_pb.SavePurposeResponse;
  }
  export class SavePrivateCorporation {
    static readonly methodName = "SavePrivateCorporation";
    static readonly service = AttributeInfoService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_attribute_info_attribute_info_service_pb.SavePrivateCorporationRequest;
    static readonly responseType = gw_attribute_info_attribute_info_service_pb.SavePrivateCorporationResponse;
  }
  export class SaveOrderFormat {
    static readonly methodName = "SaveOrderFormat";
    static readonly service = AttributeInfoService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_attribute_info_attribute_info_service_pb.SaveOrderFormatRequest;
    static readonly responseType = gw_attribute_info_attribute_info_service_pb.SaveOrderFormatResponse;
  }
  export class SaveContractStyle {
    static readonly methodName = "SaveContractStyle";
    static readonly service = AttributeInfoService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_attribute_info_attribute_info_service_pb.SaveContractStyleRequest;
    static readonly responseType = gw_attribute_info_attribute_info_service_pb.SaveContractStyleResponse;
  }
  export class SaveBudget {
    static readonly methodName = "SaveBudget";
    static readonly service = AttributeInfoService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_attribute_info_attribute_info_service_pb.SaveBudgetRequest;
    static readonly responseType = gw_attribute_info_attribute_info_service_pb.SaveBudgetResponse;
  }
  export class GetInitialCorporationInformationBuyer {
    static readonly methodName = "GetInitialCorporationInformationBuyer";
    static readonly service = AttributeInfoService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_attribute_info_attribute_info_service_pb.GetInitialCorporationInformationBuyerRequest;
    static readonly responseType = gw_attribute_info_attribute_info_service_pb.GetInitialCorporationInformationBuyerResponse;
  }
  export class SaveCorporationInformationBuyer {
    static readonly methodName = "SaveCorporationInformationBuyer";
    static readonly service = AttributeInfoService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_attribute_info_attribute_info_service_pb.SaveCorporationInformationBuyerRequest;
    static readonly responseType = gw_attribute_info_attribute_info_service_pb.SaveCorporationInformationBuyerResponse;
  }
  export class ConfirmPopupDisplayForActionA {
    static readonly methodName = "ConfirmPopupDisplayForActionA";
    static readonly service = AttributeInfoService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_attribute_info_attribute_info_service_pb.ConfirmPopupDisplayForActionARequest;
    static readonly responseType = gw_attribute_info_attribute_info_service_pb.ConfirmPopupDisplayForActionAResponse;
  }
  export class LimitPopupDisplayForActionB {
    static readonly methodName = "LimitPopupDisplayForActionB";
    static readonly service = AttributeInfoService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_attribute_info_attribute_info_service_pb.LimitPopupDisplayForActionBRequest;
    static readonly responseType = gw_attribute_info_attribute_info_service_pb.LimitPopupDisplayForActionBResponse;
  }
  export class SaveDesiredWorkingCondition {
    static readonly methodName = "SaveDesiredWorkingCondition";
    static readonly service = AttributeInfoService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_attribute_info_attribute_info_service_pb.SaveDesiredWorkingConditionRequest;
    static readonly responseType = gw_attribute_info_attribute_info_service_pb.SaveDesiredWorkingConditionResponse;
  }
}
